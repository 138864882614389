.filter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E1E1E1;
    padding: 13px 40px;
    min-height: 72px;
    @media( max-width: 768px ) {
        padding: 16px 24px 8px 24px;
    }
    @media( max-width: 450px ) {
        padding: 16px 16px 8px 16px;
    }
    .filter-toggle-wrapper {
        display: flex;
        align-items: center;
        @media( max-width: 768px ) {
            flex-direction: column;
            align-items: flex-start;
        }
        .toggle-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 20px;
            @media( max-width: 768px ) {
                margin-bottom: 8px;
                align-items: space-between;
            }
            .icon {
                display: flex;
                align-items: center;
                margin-right: 16px;
                transition: .3s;
            }
            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
                letter-spacing: 0.8px;
            }
        }
        .results-wrapper {
            display: flex;
            flex-wrap: wrap;
            @media( max-width: 768px ) {
                margin: 0 -4px;
            }
            .box {
                display: flex;
                align-items: center;
                padding: 0 6px 0 12px;
                border: 1px solid #CCC;
                margin: 8px 4px;
                transition: .3s;
                cursor: pointer;
                &:hover {
                    text-shadow: 1px 0 0 currentColor;
                }
            }
            .icon {
                display: flex;
                align-items: center;
            }
        }
    }
    .clear-wrapper {
        display: flex;
        margin-left: 15px;
        @media( max-width: 768px ) {
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        p {
            white-space: nowrap;
            &:first-of-type {
                margin-right: 24px;
                cursor: pointer;
                @media( max-width: 768px ) {
                    margin-right: 0;
                }
            }
        }
    }
}
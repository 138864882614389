.projects {
    .container {
        .projects-wrapper {
            display: flex;
            flex-wrap: wrap;
            .project-item {
                max-width: 25%;
                width: 100%;
                @media( max-width: 1100px ) {
                    max-width: 33.3%;
                }
                @media( max-width: 900px ) {
                    max-width: 50%;
                }
                @media( max-width: 450px ) {
                    max-width: 100%;
                }
            }
        }
        .search-results-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #E1E1E1;
            padding: 13px 40px;
            @media( max-width: 768px ) {
                padding: 16px 24px 8px 24px;
            }
            @media( max-width: 450px ) {
                padding: 16px 16px 8px 16px;
            }
            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
                letter-spacing: 0.8px;
            }
            .clear-wrapper {
                display: flex;
                margin-left: 15px;
                @media( max-width: 768px ) {
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }
                p {
                    white-space: nowrap;
                    &:first-of-type {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
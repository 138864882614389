.project-main {
    .presentation-project {
        display: flex;
        align-items: center;
        background-color: #E1E1E1;
        @media( max-width: 1024px ) {
            flex-direction: column;
        }
        .col {
            max-width: 50%;
            width: 100%;
            @media( max-width: 1024px ) {
                max-width: 100%;
            }
            &:first-of-type {
                padding: 87px 0 116px 195px;
                @media( max-width: 1600px ) {
                    padding: 50px 0 50px 100px;
                }
                @media( max-width: 1300px ) {
                    padding: 30px 0 30px 50px;
                }
                @media( max-width: 1024px ) {
                    padding: 51px 64px 0 64px;
                }
                @media( max-width: 450px ) {
                    padding: 18px 8px 0 8px;
                }
            }
            &:last-of-type {
                padding: 87px 195px 116px 168px;
                @media( max-width: 1600px ) {
                    padding: 50px 100px 50px 80px;
                }
                @media( max-width: 1300px ) {
                    padding: 30px 50px 30px 30px;
                } 
                @media( max-width: 1024px ) {
                    padding: 64px 64px 80px 64px;
                }
                @media( max-width: 450px ) {
                    padding: 64px 40px;
                }
            }
            .preview-image {
                position: relative;
                img {
                    max-width: 765px;
                    width: 100%;
                    max-height: 715px;
                    height: 100%;
                    object-fit: contain;
                    @media( max-width: 768px ) {
                        max-height: 480px;
                    }
                }
                .play-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    svg {
                        cursor: pointer;
                        svg {
                            path {
                                transition: .3s;
                            }
                        }
                    }
                    &:hover {
                        svg {
                            path {
                                &:last-of-type {
                                    fill: #E80808;
                                }
                            }
                        }
                    }
                }
            }
            .title-wrapper {
                margin-bottom: 24px;
                @media( max-width: 768px ) {
                    margin-bottom: 0;
                }
                h1 {
                    color: #373737;
                    /* Display/Dispaly 3 Desktop */
                    font-family: Uniform Extra Condensed;
                    font-size: 60px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%; /* 60px */
                    letter-spacing: 3px;
                    text-transform: uppercase;
                    @media( max-width: 768px ) {
                        font-size: 52px;
                        letter-spacing: 2.6px;
                    }
                }
            }
            .tags-wrapper {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -8px 32px -8px;
                p {
                    margin: 0 8px 8px 8px;
                    color: #373737;
                    /* Heading EC/H4 Desktop */
                    font-family: Uniform Condensed;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 33.6px */
                    letter-spacing: 1.2px;
                    @media( max-width: 768px ) {
                        font-size: 20px;
                        letter-spacing: 1px;
                    }
                }
            }
            .info-wrapper {
                margin-bottom: 40px;
                p {
                    color: #373737;
                    font-family: Uniform Condensed;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 175%; /* 28px */
                    letter-spacing: 0.16px;
                }
            }
            .btns-wrapper {
                display: flex;
                margin: 0 -12px 80px -12px;
                @media( max-width: 600px ) {
                    flex-direction: column;
                    margin: 0 0 42px 0;
                }
                .btn-wrapper {
                    margin: 0 12px;
                    @media( max-width: 600px ) {
                        margin: 0;
                        &:last-of-type {
                            margin-top: 16px;
                        }
                    }
                }
            }
            .icons-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0 -16px;
                @media( max-width: 500px ) {
                    margin: 0 -10px;
                }
                .icon {
                    display: flex;
                    align-items: center;
                    margin: 0 16px 16px 16px;
                    max-width: calc(50% - 32px);
                    width: 100%;
                    @media( max-width: 500px ) {
                        margin: 0 10px 16px 10px;
                        max-width: calc(50% - 20px);
                    }
                    .svg {
                        margin-right: 8px;
                    }
                    .text {
                        p {
                            color: #373737;
                            font-family: Uniform Condensed;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                            letter-spacing: 0.8px;
                            @media( max-width: 450px ) {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .size-wrapper {
                padding-top: 16px;
                border-top: 1px solid #CCC;
                .icon {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    .svg {
                        margin-right: 8px;
                    }
                    .text {
                        p {
                            color: #373737;
                            font-family: Uniform Condensed;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                            letter-spacing: 0.8px;
                        }
                    }
                }
            }
        }
    }
}
.breadcrumbs-wrapper {
    padding: 112px 40px 0 40px;
    background-color: #E1E1E1;
    @media( max-width: 768px ) {
        padding: 68px 24px 0 24px;
    }
    @media( max-width: 450px ) {
        padding: 68px 16px 0 16px;
    }
    a {
        text-decoration: none;
    }
    .item-list {
        display: flex;
        align-items: center;
        .icon {
            margin-right: 8px;
        }
        p {
            color: #373737;
            text-align: center;
            /* Text/Caption */
            font-family: Uniform Condensed;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 16.8px */
        }
    }
}
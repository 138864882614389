.filter-box-wrapper {
    position: fixed;
    right: 0;
    height: 100vh;
    top: 0;
    // max-width: 482px;
    max-width: 100vw;
    width: 100%;
    // background-color: #ffffff;
    // z-index: 10;
    // padding: 40px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // transition: .5s;
    z-index: -1;
    background-color: rgba(0,0,0,.4);
    transition: .5s;
    opacity: 0;
    &.active {
        z-index: 10;
        opacity: 1;
    }
    .box {
        position: fixed;
        right: 0;
        height: 100vh;
        top: 0;
        max-width: 482px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #ffffff;
        padding: 40px 30px 30px 30px;
        transform: translateX(100%);
        transition: .5s;
        z-index: 11;
        overflow-y: auto;
        &::-webkit-scrollbar {
            background-color: transparent;
            width: 5px;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #CCC;
            border-radius: 20px;
            max-height: 30px;
        }
        @media( max-width: 768px ) {
            max-width: 382px;
        }
        @media( max-width: 420px ) {
            max-width: 100%;
        }
        &.active {
            transform: translateX(0);
        }
    }
    .back {
        position: absolute;
        top: 16px;
        left: 30px;
        cursor: pointer;
    }
    .close {
        position: absolute;
        top: 16px;
        righT: 30px;
        cursor: pointer;
    }
    .header {
        margin-bottom: 40px;
        h3 {
            color: #373737;
            text-align: center;
            // font-family: Uniform Pro Con;
            font-family: "Uniform Condensed Medium";
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 24px */
            letter-spacing: 1.2px;
            text-transform: uppercase;
        }
    }
    .top {
        .options-wrapper {
            &.hidden {
                opacity: 0;
                height: 0;
                visibility: hidden;
            }
            .option {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                cursor: pointer;
                &:last-of-type {
                    margin-bottom: 0;
                }
                .name {
                    color: #373737;
                    // font-family: Uniform Pro Con;
                    font-family: "Uniform Condensed Medium";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%; /* 28px */
                    letter-spacing: 1px;
                    margin-right: 15px;
                }
                .value-wrapper {
                    display: flex;
                    align-items: center;
                    .value {
                        color: #808184;
                        /* Text/Caption */
                        font-family: Uniform Condensed;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 16.8px */
                    }
                    .icon {
                        margin-left: 16px;
                    }
                }
            }
        }
    }
    .bottom {
        text-align: center;
        .btn-wrapper {
            margin-top: 16px;
        }
    }
}
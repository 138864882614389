.gallery {
    margin: 116px 0 ;
    @media( max-width: 768px ) {
        margin: 64px 0;
    }
    .title-wrapper {
        padding: 0 195px;
        margin-bottom: 40px;
        @media( max-width: 1100px ) {
            padding: 0 64px;
            margin-bottom: 24px;
        }
        @media( max-width: 450px ) {
            padding: 0 40px;
        }
        h2 {
            color: #373737;
            font-family: Uniform Extra Condensed;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 44px */
            letter-spacing: 2px;
            text-transform: uppercase;
            @media( max-width: 768px ) {
                font-size: 32px;
                letter-spacing: 1.6px;
            }
            @media( max-width: 450px ) {
                font-size: 32px;
            }
        }
    }
    .gallery-wrapper {
        display: flex;
        max-width: 100vw;
        padding-left: 40px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 10px;
            background-color: transparent;
            z-index: 10px;
        }
        @media( max-width: 768px ) {
            padding-left: 0;
        }
        ::-webkit-scrollbar {
            width: 3px;
            height: 3px;
        }
        ::-webkit-scrollbar-track {
            background-color: rgb(225, 225, 225);
        }
        ::-webkit-scrollbar-thumb {
            background-color: rgb(55, 55, 55);
        }
        ::-webkit-scrollbar-thumb:hover {
            background-color: rgb(55, 55, 55);
        }
        ::-moz-selection {
            background-color: rgb(55, 55, 55);
        }
        ::selection {
            background-color: rgb(55, 55, 55);
        }
        .scrollable-wrapper {
            display: flex;
            overflow-x: auto;
        }
        .sc-Axmtr {
            display: flex!important;
        }
        .parent-scroll-image {
            position: relative;
            margin-bottom: 29px;
            max-height: 80vh;
            margin-left: 15px;
            margin-right: 15px;
            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
            @media( max-width: 768px ) {
                height: 580px!important;
            }
            &.link {
                cursor: pointer;
                &:hover {
                    .basket-wrapper {
                        transform: translateX(0);
                        .text {
                            opacity: 1;
                        }
                    }
                }
            }
            .basket-wrapper {
                position: absolute;
                right: 22px;
                bottom: 22px;
                display: flex;
                align-items: center;
                transform: translateX(calc(100% - 39px));
                transition: .3s;
                .icon {
                    svg {
                        width: 35px;
                        height: 35px;
                        fill: #ffffff;
                    }
                }
                .text {
                    text-transform: uppercase;
                    opacity: 0;
                    color: #ffffff;
                    margin-left: 15px;
                    font-size: 22px;
                }
            }
        }
        img {
            margin-bottom: 29px;
            max-height: 80vh;
            margin-left: 15px;
            margin-right: 15px;
            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
            @media( max-width: 768px ) {
                height: 580px!important;
            }
            &.link {
                cursor: pointer;
            }
        }
        .arrow {
            position: absolute;
            top: 50%;
            left: 50px;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;
            @media( max-width: 768px ) {
                left: 10px;
                svg {
                    width: 30px;
                    height: 70px;
                }
            }
            &.right {
                right: 10px;
                left: initial;
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
@font-face {
    font-family: 'Uniform Pro Con';
    src: url('../../fonts/UniformProCon-Med.woff') format('woff'),
         url('../../fonts/UniformProCon-Med.woff2') format('woff2'),
}
@font-face {
    font-family: 'Uniform Extra Condensed';
    src: url('../../fonts/UniformExtraCon.woff') format('woff'),
         url('../../fonts/UniformExtraCon.woff2') format('woff2'),
}
@font-face {
    font-family: 'Uniform Condensed';
    src: url('../../fonts/UniformCon.woff') format('woff'),
         url('../../fonts/UniformCon.woff2') format('woff2'),
}
@font-face {
    font-family: 'Uniform Condensed Medium';
    src: url('../../fonts/UniformConMedium.woff') format('woff'),
         url('../../fonts/UniformConMedium.woff2') format('woff2'),
}


  
body, html {
    margin: 0;
    padding: 0;
    background-color: #F6F5F3;
    max-width: 100vw;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    font-family: Uniform Condensed, sans-serif;
    color: #373737;
}

section {
    max-width: 100%;
    width: 100%;
}

h1,h2,h3,h4,h5,p,span,a {
    margin: 0;
}
.popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-100%);
    transition: .5s;
    &.active {
        transform: translate(0);
    }
    .close-popup {
        position: fixed;
        top: 10px;
        right: 30px;
        font-size: 28px;
        color: #ffffff;
        cursor: pointer;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 720px;
        overflow: hidden;
        .wrapper {
            max-width: 1280px;
            width: 100%;
            position: relative;
            overflow: hidden;
            padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.radio-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    input {
        width: 24px;
        height: 24px;
        margin: 0;
        margin-right: 8px;
        accent-color: #e80808;
        cursor: pointer;
    }
    label {
        color: #373737;
        font-size: 16px;
        font-family: Uniform Condensed;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.8px;
    }
}
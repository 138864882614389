.checkbox-wrapper {
    margin-bottom: 20px;
    .checkbox {
        display: flex;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        span, a {
            color: #373737;
            font-size: 16px;
            font-family: Uniform Condensed;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.8px;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    
        input:checked ~ .icon svg,
        input:checked ~ .icon .check {
            display: block;
        }
        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            @media( max-width: 1200px ) {
                max-width: 22px;
                min-width: 22px;
                max-height: 22px;
                min-height: 22px;
                .check {
                    width: 12px!important;
                    height: 12px!important;
                }
            }
            .check {
                display: none;
                background-color: #de171d;
                // width: 8px;
                // height: 8px;
                width: 18px;
                height: 18px;
            }
        }
    }
}
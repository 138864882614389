header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 100%;
    z-index: 1;
    padding: 24px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media( max-width: 768px ) {
        padding: 12px 24px;
    }
    @media( max-width: 450px ) {
        padding: 12px 16px;
    }
    &:hover {
        .search-wrapper {
            input {
                border-color: #373737;
                color: #373737;
                &::placeholder {
                    color: #373737;
                }
            }
            svg {
                path {
                    fill: #373737;
                }
            }
        }
        .actions-wrapper {
            .link {
                a {
                    color: #373737;
                }
            }
        }
    }
    &.lang-switcher {
        background-color: #ffffff;
        .actions-wrapper {
            .flags {
                .other-flags {
                    opacity: 1;
                    width: 100%;
                    visibility: visible;
                    .flag {
                        width: auto;
                        &:nth-of-type(even) {
                            margin: 0 24px;
                        }
                    }
                }
            }
            .link {
                opacity: 0;
                visibility: hidden;
            }
        }
        .logo-wrapper, .search-wrapper {
            opacity: 0;
            visibility: hidden;
        }
    }
    .logo-wrapper {
        display: flex;
        align-items: center;
        // margin-right: 24px;
        @media( max-width: 768px ) {
            svg {
                height: 32px;
                width: 43px;
            }
        }
    }
    .search-wrapper {
        max-width: 1550px;
        width: 100%;
        position: relative;
        margin: 0 80px;
        @media( max-width: 768px ) {
            margin: 0 24px;
        }
        @media( max-width: 768px ) {
            margin: 0 24px;
        }
        @media( max-width: 400px ) {
            margin: 0 16px;
        }
        input {
            width: 100%;
            height: 48px;
            outline: none;
            background: transparent;
            border: 1px solid #ffffff;
            color: #ffffff;
            padding: 0 36px 0 15px;
            transition: .3s;
            @media( max-width: 768px ) {
                height: 32px;
            }
            &::placeholder {
                color: #ffffff;
                transition: .3s;
            }
        }
        svg {
            position: absolute;
            top: 12px;
            right: 12px;
            @media( max-width: 768px ) {
                top: 4px;
            }
            path {
                transition: .3s;
            }
        }
    }
    .actions-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        .flags {
            display: flex;
            .other-flags {
                display: flex;
                opacity: 0;
                visibility: hidden;
                width: 0;
                .flag {
                    width: 0;
                    cursor: pointer;
                }
            }
            .flag {
                cursor: pointer;
            }
        }
        .link {
            margin-top: 10px;
            @media( max-width: 768px ) {
                margin-top: 0;
            }
            a {
                text-decoration: none;
                color: #ffffff;
                font-family: Uniform Pro Con;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 22.4px */
                letter-spacing: 1.6px;
                text-transform: uppercase;
                transition: .3s;
            }
        }
    }
}
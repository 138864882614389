.btn-wrapper {
    width: 100%;
    max-width: 100%;
    &.black {
        input {
            background: #373737;
            color: #fff;
        }
    }
    input {
        background: transparent;
        width: 100%;
        border: 1px solid #373737;
        color: #373737;
        text-align: center;
        /* Special/Subtitle */
        font-family: Uniform Condensed;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.8px;
        min-height: 36px;
        height: 100%;
        padding: 6px 24px 6px 24px;
        cursor: pointer;
        transition: .3s;
        border-radius: 0!important;
        &:hover {
            background: #000000;
            color: #fff;
        }
    }
}
.project-item {
    padding: 40px;
    width: 100%;
    transition: .1s;
    @media( max-width: 768px ) {
        padding: 24px 24px 32px 24px;
    }
    @media( max-width: 450px ) {
        padding: 16px 16px 32px 16px;
        border-bottom: 1px solid #CCC;
    }
    a {
        text-decoration: none;
    }
    &:hover {
        background-color: #E9E6E4;
        .image-wrapper {
            .hover {
                opacity: 1;
            }
            .normal {
                opacity: 0;
            }
        }
        .links-wrapper {
            .project-link {
                opacity: 1;
            }
            .voxbox-link {
                transform: translateX(0);
            }
        }
    }
    .image-wrapper {
        position: relative;
        max-width: 400px;
        width: 100%;
        img {
            width: 100%;
            object-fit: contain;
            transition: .7s;
        }
        .hover {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
        }
    }
    .collection-name {
        margin: 24px 0 42px 0;
        font-family: Uniform Condensed;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 33.6px */
        letter-spacing: 1.2px;
        @media( max-width: 768px ) {
            font-size: 20px;
            letter-spacing: 1px;
        }
        @media( max-width: 450px ) {
            margin: 24px 0 18px 0;
        }
    }
    .icons-wrapper {
        border-bottom: 1px solid #CCC;
        max-width: 270px;
        padding-bottom: 8px;
        margin-bottom: 8px;
        .icon {
            display: flex;
            align-content: center;
            margin-bottom: 8px;
            .svg-icon {
                display: flex;
                align-items: center;
                svg {

                }
            }
            p {
                display: block;
                align-items: center;
                margin-left: 8px;
                font-family: Uniform Condensed;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .size-wrapper {
        margin-bottom: 2px;
        .icon {
            display: flex;
            align-items: center;
            p {
                margin-left: 8px;
                font-family: Uniform Condensed;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
            }
        }
    }
    .links-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        overflow: hidden;
        
        .project-link {
            @media( max-width: 768px ) {
                display: none;
            }
            opacity: 0;
            transition: .2s;
            a {
                font-family: Uniform Condensed;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
                text-decoration: underline;
            }
        }
        .voxbox-link {
            transform: translateX(80px);
            transition: .3s;
            @media( max-width: 768px ) {
                display: none;
            }
            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                .icon {

                }
                p {
                    width: 80px;
                    margin-left: 10px;
                    font-family: Uniform Condensed;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                }
            }
        }
        .tablet-url {
            margin-top: 16px;
            @media( min-width: 769px ) {
                display: none;
            }
            color: #373737;
            /* Text/Small Text Link */
            font-family: Uniform Condensed;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            letter-spacing: 0.7px;
            text-decoration-line: underline;
        }
    }
}
footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 110px;
    background-color: #E1E1E1;
    padding: 64px 40px;
    @media( max-width: 768px ) {
        margin-top: 80px;
        padding: 64px 16px 39px 16px;
        flex-direction: column;
        justify-content: center;
    }
    @media( max-width: 450px ) {
        margin-top: 64px;
        padding: 40px 16px;
    }
    .info {
        @media( max-width: 768px ) {
            order: 2;
            margin-top: 100px;
        }
        @media( max-width: 768px ) {
            margin-top: 80px;
        }
        p {
            color: #373737;
            text-align: center;
            /* Text/Text */
            font-family: Uniform Condensed;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
        }
    }
    .socials {
        a {
            display: inline-block;
            margin: 0 12px;
            &:last-of-type {
                margin-right: 0;
            }
            &:hover {
                svg {
                    path {
                        fill: #E80808;
                    }
                }
            }
            svg {
                path {
                    transition: .3s;
                }
            }
        }
    }
}
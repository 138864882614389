.editor-wrapper {
    p {
        color: #373737;
        font-family: Uniform Condensed;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
        letter-spacing: 0.16px;
        @media( max-width: 1024px ) {
            font-size: 80px;
            letter-spacing: 4px;
        }
    }
    h1 {
        color: #373737;
        /* Display/Display 1 Desktop */
        font-family: Uniform Extra Condensed;
        font-size: 148px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 148px */
        letter-spacing: 7.4px;
        text-transform: uppercase;
        @media( max-width: 1024px ) {
            font-size: 80px;
            letter-spacing: 4px;
        }
        @media( max-width: 450px ) {
            font-size: 60px;
            letter-spacing: 3px;
        }
    }
    h2 {
        color: #373737;
        /* Display/Dispaly 3 Desktop */
        font-family: Uniform Extra Condensed;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 60px */
        letter-spacing: 3px;
        text-transform: uppercase;
    }
}
.pagination-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 40px auto 40px auto;
    text-align: center;
    @media( max-width: 768px ) {
        max-width: 510px;
    }
    .line-wrapper {
        width: 100%;
        height: 2px;
        background: #CCC;
        .progress {
            height: 2px;
            background: #373737;
            transition: .3s;
        }
    }
    .total {
        margin: 16px 0;
        p {
            color: #373737;
            text-align: center;
            font-family: Uniform Condensed;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 16.8px */ 
        }
    }
    .btn-wrapper {
        max-width: 141px;
    }
}